<template>
	<section>
		<CourseHeader :course="course" :stage="stage" @setValue="setValue" />
		<CourseChapters
			:chapters="chapters"
			:stage="stage"
			@setValue="setValue"
		/>
		<CourseContent :course="course" :stage="stage" @setValue="setValue" />
	</section>
</template>

<script>
import CourseHeader from "./CourseHeader.vue";
import CourseChapters from "./CourseChapters.vue";
import CourseContent from "./CourseContent.vue";
import { get } from "@/util/requests/get";

export default {
	name: "Course",
	components: { CourseHeader, CourseChapters, CourseContent },
	computed: {
		chapters() {
			if (!!this.course === false) return [];
			return this.course?.chapters;
		}
	},
	data() {
		return {
			course: null,
			stage: 0
		};
	},
	watch: {
		"$route.query.chapter": function () {
			this.checkStage(this.$route.query.chapter, this.$route.query.page);
		},
		"$route.query.page": function () {
			this.checkStage(this.$route.query.chapter, this.$route.query.page);
		}
	},

	methods: {
		setValue({ key, value }) {
			this[key] = value;
		},
		checkStage(chapterId, pageId) {
			if (!!chapterId === false) {
				this.stage = 0;
			} else if (
				Number(chapterId) === this.chapters.at(-1).id &&
				Number(pageId) === this.chapters.at(-1).pages.at(-1).id
			) {
				this.stage = 2;
			} else {
				this.stage = 1;
			}
		},
		async courseContent(courseId) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const {
					data: { data }
				} = await get(`/course/${this.$route.query.course}`);
				this.course = data;

				let course_obj = data.chapters[0]
				let chapter_id = course_obj.id
				let page_id = course_obj.pages[0].id

				this.$router.push({
					query: {
						...this.$route.query,
						studentCourse: this.$route.query.studentCourse,
						chapter: chapter_id,
						page: page_id
					}
				});

			} catch (error) {
				console.error(error);
			}

			await this.$store.dispatch("setIsLoading", false);
		},
		async setDataToLocalStorage() {
			if (
				Number(
					!!localStorage.getItem(
						`courseData[${this.$route.query.course}]`
					) === true
				)
			) {
				const courseData = JSON.parse(
					localStorage.getItem(
						`courseData[${this.$route.query.course}]`
					)
				);
				const chapterId = courseData.chapterId;
				const pageId = courseData.pageId;
				localStorage.setItem(
					`courseData[${this.$route.query.course}]`,
					JSON.stringify({
						chapterId,
						pageId
					})
				);

				this.$router.push({
					query: {
						...this.$route.query,
						studentCourse: this.$route.query.studentCourse,
						chapter: chapterId,
						page: pageId
					}
				});
			}
		}
	},
	async created() {
		await this.$store.dispatch("setIsLoading", true);
		await this.courseContent();
		await this.setDataToLocalStorage();
		await this.$store.dispatch("setIsLoading", false);
		this.checkStage(this.$route.query.chapter, this.$route.query.page);
	}
};
</script>
